.placeholder-custom::placeholder {
  color: #8c8278;
  font-weight: 500;
}

.mt-2 {
  margin-top: 0.5rem;
}

.collapsed {
  max-height: 40px; /* Height when collapsed */
  overflow: hidden;
  transition: max-height 0.3s ease;
}

.expanded {
  max-height: 300px; /* Adjust as needed for expanded state */
  overflow: auto;
  transition: max-height 0.3s ease;
}

.suggestions {
  transition: max-height 0.3s ease;
}

button {
  /* Ensure the cursor changes to pointer on hover */
  cursor: pointer;
  background: none;
  border: none;
  font-size: 1.2rem; /* Adjust size as needed */
  padding: 0;
}

button:focus {
  outline: none;
}

.markdown-content * {
  color: #433e39 !important;
}

.markdown-content {
  font-size: 0.875rem;
}

@media (min-width: 640px) {
  .markdown-content {
    font-size: 1rem;
  }
}

/* Markdown list styling */
.markdown-content ul,
.markdown-content ol {
  color: #433e39;
  margin-left: 1em;
  padding-right: 1em;
}

@media (min-width: 640px) {
  .markdown-content ul,
  .markdown-content ol {
    margin-left: 1.5em;
  }
}

.markdown-content ul li::marker,
.markdown-content ol li::marker {
  color: #433e39;
}

.markdown-content li {
  color: #433e39;
  margin: 0.25em 0;
}

.markdown-content li > p {
  margin: 0;
  display: inline;
}

/* Smooth scrolling container */
.scroll-smooth {
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
}

/* Hide scrollbar but keep functionality */
.scroll-smooth::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}

/* Fade effect for messages */
.fade-edges {
  -webkit-mask-image: linear-gradient(
    to bottom,
    transparent,
    black 5%,
    black 95%,
    transparent 100%
  );
  mask-image: linear-gradient(
    to bottom,
    transparent,
    black 5%,
    black 95%,
    transparent 100%
  );
}